import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AccountPage.css';
import { API_BASE_URL } from './config';
import Navbar from './Navbar';
import Footer from './Footer';

const MyAccount = () => {
  const [activeTab, setActiveTab] = useState('details');
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    phoneNumber: '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [settings, setSettings] = useState({
    receiveNewsletters: false,
    deactivateAccount: false,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch user details on component mount
  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserDetails(response.data);
      setError('');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch profile');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.put(`${API_BASE_URL}/api/profile`, 
        {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setSuccess('Profile updated successfully');
      setError('');
      setActiveTab('details');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    if (passwordData.newPassword.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/api/change-password`, // Added /api/
        {
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setSuccess('Password changed successfully');
      setError('');
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      setActiveTab('details');
    } catch (err) {
      if (err.response?.status === 400) {
        setError('Current password is incorrect');
      } else if (err.response?.status === 401) {
        setError('Session expired. Please login again');
        localStorage.removeItem('token');
        window.location.href = '/LoginPage';
      } else {
        setError(err.response?.data?.error || 'Failed to change password');
      }
    } finally {
      setLoading(false);
    }
};

const handleDeleteAccount = async () => {
    if (!window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.delete(`${API_BASE_URL}/api/delete-account`, { // Added /api/
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccess('Account deleted successfully');
      localStorage.removeItem('token');
      setTimeout(() => {
        window.location.href = '/LoginPage';
      }, 1500); // Give user time to see success message
    } catch (err) {
      if (err.response?.status === 401) {
        setError('Session expired. Please login again');
        localStorage.removeItem('token');
        window.location.href = '/LoginPage';
      } else {
        setError(err.response?.data?.error || 'Failed to delete account');
      }
    } finally {
      setLoading(false);
    }
};

const handleLogout = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(`${API_BASE_URL}/api/logout`, null, { // Added /api/
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccess('Logged out successfully');
      localStorage.removeItem('token');
      setTimeout(() => {
        window.location.href = '/';
      }, 1000); // Give user time to see success message
    } catch (err) {
      if (err.response?.status === 401) {
        // If token is invalid, just log them out anyway
        localStorage.removeItem('token');
        window.location.href = '/LoginPage';
      } else {
        setError('Failed to logout properly, clearing session...');
        setTimeout(() => {
          localStorage.removeItem('token');
          window.location.href = '/LoginPage';
        }, 1500);
      }
    } finally {
      setLoading(false);
    }
};

  return (
    <>
    <Navbar />
    
    <div className="account-container">
      
      {/* Sidebar Navigation */}
      <div className="sidebar">
        <h2>My Account</h2>
        <ul>
          <li 
            className={activeTab === 'details' ? 'active' : ''} 
            onClick={() => setActiveTab('details')}
          >
            My details
          </li>
          <li 
            className={activeTab === 'edit' ? 'active' : ''} 
            onClick={() => setActiveTab('edit')}
          >
            Edit Profile
          </li>
          <li 
            className={activeTab === 'password' ? 'active' : ''} 
            onClick={() => setActiveTab('password')}
          >
            Change Password
          </li>
          <li 
            className={activeTab === 'settings' ? 'active' : ''} 
            onClick={() => setActiveTab('settings')}
          >
            Account settings
          </li>
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="details-container">
        {/* Error and Success Messages */}
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        {loading && <div className="loading">Loading...</div>}

        {/* Breadcrumbs */}
        <div className="breadcrumbs">
          Homepage / My Account {activeTab !== 'details' && `/ ${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}`}
        </div>

        {/* Content Sections */}
        {activeTab === 'details' && (
          <>
            <h2>My details</h2>
            <div className="form-section">
              <h3>Personal Information</h3>
              <p>View and manage your personal information.</p>
              <form>
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" value={userDetails.firstName} disabled />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" value={userDetails.lastName} disabled />
                </div>
              </form>
            </div>
            <div className="form-section">
              <h3>E-mail address</h3>
              <p>Assertively utilize adaptive customer service for future-proof platforms. Completely drive optimal markets.</p>
              <div className="form-group">
                <label>E-mail address</label>
                <input type="email" value={userDetails.email} placeholder="email@example.pl" disabled />
              </div>
            </div>
          </>
        )}

        {activeTab === 'edit' && (
          <>
            <h2>Edit Profile</h2>
            <form onSubmit={handleUpdateProfile}>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  value={userDetails.firstName}
                  onChange={(e) => setUserDetails({...userDetails, firstName: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={userDetails.lastName}
                  onChange={(e) => setUserDetails({...userDetails, lastName: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={userDetails.email}
                  onChange={(e) => setUserDetails({...userDetails, email: e.target.value})}
                  required
                />
              </div>
              <button className="save-button" type="submit" disabled={loading}>
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </form>
          </>
        )}

        {activeTab === 'password' && (
          <>
            <h2>Change Password</h2>
            <form onSubmit={handleChangePassword}>
              <div className="form-group">
                <label>Current Password</label>
                <input
                  type="password"
                  value={passwordData.currentPassword}
                  onChange={(e) => setPasswordData({...passwordData, currentPassword: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  value={passwordData.newPassword}
                  onChange={(e) => setPasswordData({...passwordData, newPassword: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  value={passwordData.confirmPassword}
                  onChange={(e) => setPasswordData({...passwordData, confirmPassword: e.target.value})}
                  required
                />
              </div>
              <button className="save-button" type="submit" disabled={loading}>
                {loading ? 'Changing Password...' : 'Change Password'}
              </button>
            </form>
          </>
        )}

        {activeTab === 'settings' && (
          <>
            <h2>Account Settings</h2>
            <form>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={settings.receiveNewsletters}
                    onChange={(e) => setSettings({...settings, receiveNewsletters: e.target.checked})}
                  />
                  Receive Newsletters
                </label>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="logout-button"
                  onClick={handleLogout}
                  disabled={loading}
                >
                  Logout
                </button>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="delete-account-button"
                  onClick={handleDeleteAccount}
                  disabled={loading}
                >
                  Delete Account
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
    <Footer />
    </>
  );
};

export default MyAccount;