import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, message } from 'antd';

const MyBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancellingId, setCancellingId] = useState(null);

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('http://localhost:5000/api/bookings/user', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setBookings(response.data);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      message.error('Failed to load bookings. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelBooking = async (bookingId) => {
    try {
      setCancellingId(bookingId); // Show loading state for the specific button

      // Make API call to cancel the booking
      await axios.delete(`http://localhost:5000/api/bookings/${bookingId}`);
      
      // Update local state to remove the cancelled booking
      setBookings(prevBookings => 
        prevBookings.filter(booking => booking._id !== bookingId)
      );
      
      message.success('Booking cancelled successfully');
    } catch (error) {
      console.error('Error cancelling booking:', error);
      
      // Show specific error messages based on the error type
      if (error.response?.status === 404) {
        message.error('Booking not found. It may have already been cancelled.');
      } else if (error.response?.status === 401) {
        message.error('You are not authorized to cancel this booking.');
      } else {
        message.error('Failed to cancel booking. Please try again later.');
      }
    } finally {
      setCancellingId(null); // Reset loading state
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (timeString) => {
    return new Date(`1970-01-01T${timeString}`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        Loading your bookings...
      </div>
    );
  }

  if (!bookings.length) {
    return (
      <div className="text-center py-8">
        <h2 className="text-2xl font-bold mb-4">My Bookings</h2>
        <p>You don't have any bookings yet.</p>
      </div>
    );
  }

  return (
    <div className="my-bookings p-4">
      <h2 className="text-2xl font-bold mb-6">My Bookings</h2>
      <div className="space-y-4">
        {bookings.map(booking => (
          <div 
            key={booking._id} 
            className="booking-item border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
          >
            <h3 className="text-xl font-semibold mb-2">
              {booking.service?.name || 'Unnamed Service'}
            </h3>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <p className="text-gray-600">Date</p>
                <p className="font-medium">{formatDate(booking.date)}</p>
              </div>
              <div>
                <p className="text-gray-600">Time</p>
                <p className="font-medium">{formatTime(booking.time)}</p>
              </div>
              <div>
                <p className="text-gray-600">Status</p>
                <p className={`font-medium capitalize ${
                  booking.status === 'confirmed' ? 'text-green-600' :
                  booking.status === 'pending' ? 'text-yellow-600' :
                  'text-gray-600'
                }`}>
                  {booking.status}
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                type="primary"
                danger
                onClick={() => handleCancelBooking(booking._id)}
                loading={cancellingId === booking._id}
                disabled={booking.status === 'cancelled' || cancellingId === booking._id}
              >
                {booking.status === 'cancelled' ? 'Cancelled' : 'Cancel Booking'}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyBookings;