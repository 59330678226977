import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent } from './ui/card';
import { Button } from './ui/button';

const BookingSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { booking } = location.state || {};

  // Handle case where booking data is not available
  if (!booking) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <Card className="w-full max-w-md">
          <CardHeader>
            <h2 className="text-2xl font-bold text-center text-red-600">Booking Information Not Found</h2>
          </CardHeader>
          <CardContent className="flex justify-center gap-4">
            <Button onClick={() => navigate('/')}>Go to Home</Button>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <h2 className="text-2xl font-bold text-center text-green-600">Booking Confirmed!</h2>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="bg-green-50 p-4 rounded-lg">
              <h3 className="font-semibold text-lg mb-2">Client Details</h3>
              <p><span className="font-medium">Name:</span> {booking.client_name}</p>
              <p><span className="font-medium">Email:</span> {booking.client_email}</p>
              <p><span className="font-medium">Phone:</span> {booking.client_phone}</p>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg">
              <h3 className="font-semibold text-lg mb-2">Appointment Details</h3>
              <p><span className="font-medium">Service:</span> {booking.service}</p>
              <p><span className="font-medium">Date:</span> {booking.date}</p>
              <p><span className="font-medium">Time:</span> {booking.time}</p>
            </div>

            {booking.message && (
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">Additional Message</h3>
                <p>{booking.message}</p>
              </div>
            )}

            <div className="flex justify-center gap-4 mt-6">
              <Button 
                onClick={() => navigate('/')}
                className="bg-blue-600 hover:bg-blue-700"
              >
                Go to Home
              </Button>
              <Button 
                onClick={() => navigate(`/schedule/${booking.service}`)}
                className="bg-gray-600 hover:bg-gray-700"
              >
                Book Another
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BookingSummary;